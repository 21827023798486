var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"mode":"out-in"}},[(_vm.visible)?_c('div',{staticClass:"EditTags",class:{ active: _vm.msg != '' }},[(_vm.dialogVisible)?_c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"width":"30%","destroy-on-close":true,"modal-append-to-body":false,"before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('span',{staticStyle:{"font-size":"19px","color":"#5e6d82"}},[_vm._v(_vm._s(_vm.$t("issave")))]),_c('p'),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.closeTagPanel(true)}}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.saveTag()}}},[_vm._v(_vm._s(_vm.$t("save")))])],1)]):_vm._e(),_c('div',{attrs:{"id":"tag"}},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.dialogVisible = true;
          _vm.closeTagPanel(JSON.stringify(_vm.tagsOrigin) === JSON.stringify(_vm.tags));}}},[(this.$route.path != '/postvideo')?_c('i',{staticClass:"el-icon-close",attrs:{"id":"close"}}):_vm._e()]),_c('div',{staticClass:"minibox"},[_c('div',{staticClass:"m_bg"}),_c('div',{staticClass:"m_a activeTag"},_vm._l((_vm.tagCategoriesAll),function(v){return _c('ul',{key:v,staticClass:"Taglist",class:v},_vm._l((_vm.TagCategoriesData),function(i,item){return _c('span',{key:item},[(i === v)?_c('div',{staticClass:"item",class:{ selected: -1 === _vm.tagsForRec.indexOf(item) },on:{"click":function($event){$event.stopPropagation();return _vm.selected(i, item)}}},[_c('div',[_c('p',{class:"val_" + item},[_vm._v(_vm._s(item))]),_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteObj(i, item)}}},[_c('i',{staticClass:"el-icon-close"})])])]):_vm._e()])}),0)}),0),_c('div',{staticClass:"m_b"},[_c('div',{attrs:{"id":"m_b_box"}},[_c('div',{attrs:{"id":"ipt"}},[_c('el-autocomplete',{attrs:{"fetch-suggestions":_vm.querySearchAsync2,"trigger-on-focus":false,"popper-class":"my-autocomplete","placeholder":_vm.$t('enter_tag')},on:{"select":_vm.handleSelect2,"focus":function($event){return _vm.infoTipEvent(true)},"blur":function($event){return _vm.infoTipEvent(false)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addTag($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"adviceList"},[_c('div',{staticClass:"name",class:{
                        Copyright: item.cat == 2,
                        Language: item.cat == 5,
                        Character: item.cat == 1,
                        Author: item.cat == 3,
                        General: item.cat == 0,
                        Meta: item.cat == 4,
                        Soundtrack: item.cat == 6,
                      },domProps:{"innerHTML":_vm._s(item.tag || _vm.ConvertLangRes(item.langs))}}),_c('div',{staticClass:"addr"},[_vm._v(_vm._s(item.cnt))])])]}}],null,false,3007981393),model:{value:(_vm.iptVal),callback:function ($$v) {_vm.iptVal=$$v},expression:"iptVal"}}),_c('a',{attrs:{"href":"javascript:;"},on:{"click":_vm.addTag}},[_c('i',{staticClass:"el-icon-plus",attrs:{"id":"add"}})])],1)]),_c('span',{staticClass:"tag_title infoTip_1",class:{ hidden: _vm.infoTip[0].isHidden }},[_vm._v(_vm._s(_vm.$t("edit_common_tags")))]),_c('span',{staticClass:"tag_title infoTip_2",class:{ show: _vm.infoTip[1].isHidden }},[_vm._v(_vm._s(_vm.$t("tag_already_exist")))]),_c('span',{staticClass:"tag_title infoTip_3",class:{ show: _vm.infoTip[2].isHidden }},[_vm._v(_vm._s(_vm.$t("tag_not_exist")))])]),_c('div',{staticClass:"m_c"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("recommnad_tags")))]),_c('transition',{attrs:{"mode":"out-in"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.recTagsWatch),expression:"recTagsWatch"}],staticClass:"recTag Taglist"},_vm._l((_vm.recTags),function(i,item){return _c('li',{key:item,staticClass:"item"},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.getiptVal(i, item)}}},[_c('p',{staticClass:"val_${str[i]}"},[_vm._v(_vm._s(Object.keys(i)[0]))])])])}),0)])],1)])]),_c('a',{attrs:{"href":"javascript:;"}},[(this.$route.path != '/postvideo')?_c('a',{staticStyle:{"font-size":"28px"},attrs:{"id":"save"},on:{"click":function($event){return _vm.saveTag()}}},[_vm._v(_vm._s(_vm.$t("save")))]):_vm._e()])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }